import React, { useEffect, useState } from 'react'; 
import { Link } from 'react-router-dom';
import { getCircular } from '../Service/Api';
import LazyLoad from 'react-lazyload';

const EMagazine = () => {
    useEffect(() => {
        document.title = 'EMagazine - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
    }, []);  
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState("All");
    const [selectedMonth, setSelectedMonth] = useState("All");
    const [yearRanges, setYearRanges] = useState([]);
    const [availableMonths, setAvailableMonths] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const monthsOfYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const handleMonth = (month) => {
        return monthsOfYear[month - 1];
    };

    const emptyArray = [
        { title: "Title" },
        { title: "Title" },
    ];

    useEffect(() => {
      if(filterData) {
            console.log(filterData);
      }
    }, [filterData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const galleryData = await getCircular();
                console.log("Fetched data:", galleryData); // Debugging log
                setData(galleryData);

                const uniqueYears = Array.from(
                    new Set(galleryData.flatMap((item) => {
                        if (item.date) {
                            const year = new Date(item.date).getFullYear();
                            return [year];
                        } else {
                            return [];
                        }
                    }))
                ).sort((a, b) => b - a);

                setYearRanges(uniqueYears);
            } catch (error) {
                console.error("Error fetching data:", error); // Debugging log
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        let filteredData = data;
        let monthsSet = new Set();

        if (selectedYear !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.date) {
                    const year = new Date(item.date).getFullYear();
                    if (parseInt(selectedYear) === year) {
                        const month = new Date(item.date).getMonth() + 1;
                        monthsSet.add(month);
                        return true;
                    }
                    return false;
                }
                return false;
            });
        } else {
            filteredData.forEach(item => {
                if (item.date) {
                    const month = new Date(item.date).getMonth() + 1;
                    monthsSet.add(month);
                }
            });
        }

        setAvailableMonths(Array.from(monthsSet).sort((a, b) => a - b));

        if (selectedMonth !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.date) {
                    const month = new Date(item.date).getMonth() + 1;
                    return parseInt(selectedMonth) === month;
                } else {
                    return false;
                }
            });
        }

        if (searchQuery) {
            filteredData = filteredData.filter((item) =>
                item.title.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        setFilteredData(filteredData);
        console.log("Filtered data:", filteredData); // Debugging log
    }, [selectedYear, selectedMonth, searchQuery, data]);

    return (
        <>
           
        <div className="innerslide">
        <ul className="breadcrumb">
            <li><Link to="/"> Home</Link> </li> 
            <li>  Academic</li>
             <li> E-magzine </li>
        </ul>
    </div> 
    <div className="innersec">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1> E-magzine </h1>
                </div>
            </div>
                <div className="row tabs-dynamic">
                    {/* <div className="col-md-3">
                        <div className="count-val">
                            <p>Total Count: {filterData.length}</p>
                        </div>
                    </div> */}
                    {/* <div className="col-md-3">
                        <div className="month-selection">
                            <select id="yearSelection" onChange={(e) => setSelectedYear(e.target.value)} value={selectedYear}>
                                <option value="All">All Years</option>
                                {yearRanges.map((yr, index) => (
                                    <option key={index} value={yr}>{yr}</option>
                                ))}
                            </select>
                        </div>
                    </div>  
                     <div className="col-md-3">
                        <div className="month-selection">
                            <select className="monthSelect" onChange={(e) => setSelectedMonth(e.target.value)} value={selectedMonth}>
                                <option value="All">All Months</option>
                                {availableMonths.map((month, index) => (
                                    <option key={index} value={month}>{handleMonth(month)}</option>
                                ))}
                            </select>
                        </div>
                    </div> */} 
                    <div className="col-md-3">
                        <div className="count-val">
                            <p>Total Count: {filterData.length}</p>
                        </div>
                    </div> 
                    <div className="col-md-3">
                        <div className="month-selection">
                            <select id="yearSelection" onChange={(e) => setSelectedYear(e.target.value)} value={selectedYear}>
                                <option value="All">All Years</option>
                                {yearRanges.map((yr, index) => (
                                    <option key={index} value={yr}>{yr}</option>
                                ))}
                            </select>
                        </div>
                    </div>  
                    <div className="col-md-6">
                        <div className="searchBox">
                            <input 
                                type="text" 
                                id="myInput" 
                                name="name" 
                                autoComplete="off" 
                                placeholder="Search Here.." 
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="clr"></div>
                <div className="row">
                    {filterData?.length > 0 ? filterData?.filter((item) => item.category === "Magazine").map((item, index) => (
                        <div key={index} className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth magzine">
                            
                                <Link to={`https://webapi.entab.info/api/image/${item?.attachments[0]}`} target="_blank">
                                    <LazyLoad><img src={`https://webapi.entab.info/api/image/${item?.images[0]}`} className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></LazyLoad>
                                     <h4> {item.title} </h4>
                                </Link> 
                            
                        </div>
                    ))
                    :
                    emptyArray.map((item, index) => (
                        <div key={index} className="col-lg-3 col-md-6 col-sm-6 col-6 fullwidth magzine"> 
                            
                                <Link to="#" target="_blank">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/MGMB/public/Images/magzine.png" className="img-fluid" alt="Mar Gregorios Memorial Hr. Sec. School, Bokaro" /></LazyLoad>
                                     <h4> {item.title}  </h4>
                                </Link>
                             
                        </div>
                    ))}
                </div>
            </div>
            </div>
        </>
    )
}

export default EMagazine;
