import React, { useEffect } from "react"; 
import LazyLoad from "react-lazyload";
import { Link } from 'react-router-dom' 
const FeeDetails = () => {
    useEffect(() => {
        document.title = 'Fee Details - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
    }, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Academic</li>
                 <li>Fee Details</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Fee Details</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                    <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/MGMB/public/Images/Fee-Details-Class-I-to-X.jpg">
                         <LazyLoad><img className="img-fluid mx-auto d-block feeimg" src="https://webapi.entab.info/api/image/MGMB/public/Images/Fee-Details-Class-I-to-X.jpg" alt="mgmb"/> </LazyLoad>
                         </a>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default FeeDetails

