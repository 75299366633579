import { useEffect, useState } from "react"; 
import Slider from "react-slick"; 
import { getTopper } from '../Service/Api';
import LazyLoad from "react-lazyload";
const TopperXII = () => { 
  var settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };
  
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const topperData = await getTopper();
        setData(topperData);
      } catch (error) {
        console.error("Error fetching topper data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    console.log("topper_data", data);
    console.log("topper_data", data.length +'test');
  }, [data]);

  const emptyArray = [
    {
      name: "Student Name",
      stream: "Stream",
      division: "100%",
      attachments: "https://webapi.entab.info/api/image/MGMB/public/Images/trophy.png",
    },
    {
      name: "Student Name",
      stream: "Stream",
      division: "100%",
      attachments: "https://webapi.entab.info/api/image/MGMB/public/Images/trophy.png",
    },
    {
      name: "Student Name",
      stream: "Stream",
      division: "100%",
      attachments: "https://webapi.entab.info/api/image/MGMB/public/Images/trophy.png",
    },
    {
      name: "Student Name",
      stream: "Stream",
      division: "100%",
      attachments: "https://webapi.entab.info/api/image/MGMB/public/Images/trophy.png",
    },
    {
      name: "Student Name",
      stream: "Stream",
      division: "100%",
      attachments: "https://webapi.entab.info/api/image/MGMB/public/Images/trophy.png",
    },
    {
      name: "Student Name",
      stream: "Stream",
      division: "100%",
      attachments: "https://webapi.entab.info/api/image/MGMB/public/Images/trophy.png",
    },
  ];
  return (
    <>
      <div className="row">
        <Slider {...settings}>
        {data.filter((item)=>(item.class==='XII')).length > 0
        ? data
            .filter((item) => item.class === "XII")
            .map((item, index) => (
              <div key={index}>
                    <div className="item">
                    <div className="topperdiv">
                      <div className="toppertaj">
                        <LazyLoad><img src={`https://webapi.entab.info/api/image/${item.attachments[0]}`} className="img-fluid" /></LazyLoad>
                      </div>

                      <div className="topperdesc">
                        <p className="name">{item.name}<span>{item.stream}</span></p>
                        <p className="per">{item.division}</p>
                      </div>
                    </div>
                  </div>
              </div>
 

      ))
        : emptyArray.map((data, index) => (
          <div key={index}> 
      <div className="item">
        <div className="topperdiv">
          <div className="toppertaj">
            <LazyLoad><img src={data.attachments} className="img-fluid" /></LazyLoad>
          </div>

          <div className="topperdesc">
            <p className="name">{data.name}<span>{data.stream}</span></p>
            <p className="per">{data.division}</p>
          </div>
        </div>
      </div>
          </div>
          ))}

        </Slider>
      </div>
    </>
  )
}

export default TopperXII