import React, { useEffect } from "react"; 
import LazyLoad from "react-lazyload";
import { Link } from 'react-router-dom' 
const Faculty = () => {
   useEffect(() => {
      document.title = 'Faculty - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
  }, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  School</li>
                 <li>Faculty </li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Faculty </h1>
                    </div>
                </div>
                <div className="row">
                <div className="col-12">
                  <div className="para">
                  <h3 className="fac"><span>  Team  MGM</span></h3>
             <div className="staffboxsec">
                   <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/366.jpg"/></LazyLoad> </div> <div className="desc"> <p> FR. DR. JOSHI VARGHESE <span> PRINCIPAL  </span><span> Ph.D, M.A, B.Ed, PG Dip in Guidance and Counselling </span> </p> </div> 
             </div>
                    <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/academic-director.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mr.George Joseph <span> Academic Director  </span><span> M.A (Eng,Eco),B.Ed </span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1414.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. RAKHI BANERJEE <span> VICE PRINCIPAL </span> <span> M.Sc ,B.Ed</span> </p></div> 
             </div>
            
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/242.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. SAPNA JOSHI <span> HEAD MISTRESS </span> <span> M.A, M.S.W, PG  Dip in Guidance and Counselling, B.Ed</span>  </p></div> 
             </div>
            
             </div>
                <h3 className="fac"><span> Teaching  Staff </span></h3>
             <div className="staffboxsec">
              <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/329.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. TRIPTI PATHAK <span> COUNSELLOR  </span><span> M. A, B. Ed, Ph.D</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/250.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. MALA SINHA <span> TEACHER  </span><span> MA , B.Ed</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/255.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. PUNAM JHA <span> TEACHER </span> <span> M.Sc , B.Ed</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/259.jpg"/></LazyLoad> </div> <div className="desc"> <p> Miss. ALAKANANDA DAS <span> TEACHER  </span><span> M.Sc ,  D.EL.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/258.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mr. MAHENDRA SINGH <span> TEACHER  </span><span> M.Sc , B.Ed</span> </p>  </div> 
             </div>
            
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/276.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mr. PRANAY KUMAR <span> TEACHER </span> <span> M.Sc , B.Ed</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/260.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mr. BHARAT BHASKAR <span> TEACHER  </span><span> M.Sc ,B.Ed , M.Phill</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/268.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. SANGEETA MISHRA <span> TEACHER </span> <span>  B.Sc , B.Ed</span> </p></div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1415.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mr. CHANDAN KUMAR <span> TEACHER  </span><span> M.Sc, B Tech, B.Ed</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/281.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. KALPANA SINHA <span> TEACHER  </span><span> MA , B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/270.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. AVINA DEY <span> TEACHER  </span><span> M.Com ,B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/278.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mr. JACOB MATHEWS <span> TEACHER  </span><span> M.Com , SET ,B.Ed.</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/273.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. MINI REJI <span> TEACHER  </span><span> M.Sc , B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/280.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. JYOTI MALHAN <span> TEACHER </span> <span> MA ,M.Ed , B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/275.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. MAMTA KUMARI <span> TEACHER </span> <span> B.Sc,B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/279.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. ARCHANA BHATNAGAR <span> TEACHER </span> <span> MA ,B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/267.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. JONAKI BISWAS <span> TEACHER </span> <span> MA , B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/283.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. REENA KUMARI RAM <span> TEACHER </span> <span> BA ,B.Ed</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/286.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mr. SUNIL KUMAR <span> TEACHER </span> <span> MA ,B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/266.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. SUBHADRA SINGH <span> TEACHER </span> <span> BA</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/290.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mr. ARVIND KUMAR SINHA <span> TEACHER </span> <span> M.Sc,B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/287.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. ARCHANA KUMARI <span> TEACHER </span> <span> M.Sc ,B.Ed</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/265.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. SUSHMA KUMARI KUJUR <span> TEACHER </span> <span> BA , B.Ed</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/294.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. RANJITA GIRI <span> ART TEACHER </span> <span> MA,BFA</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/296.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. NILANJANA CHOUDHARY <span> TEACHER </span> <span> MA ,B.Ed.</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/284.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mr. RAJESHWAR SINGH <span> PET </span> <span> BA ,D.P.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/288.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mr. BHAVESH JHA <span> TEACHER </span> <span> M.Sc, B.Ed ,M.Phill ,MBA</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/295.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. KUMARI RUPAM <span> TEACHER </span> <span> MA ,B.Ed</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/298.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mr. VIVEK KUMAR TIWARY <span> TEACHER </span> <span> B.Sc ,MA , B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/299.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. DIVYA <span> TEACHER </span> <span> B.Sc, MCA , MIT M.Phill</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/362.jpg"/></LazyLoad> </div> <div className="desc"> <p> Miss. SHAFQUAT JAHAN <span> TEACHER </span><span> MA, B.Ed</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/301.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. SEEMA PANDEY <span> TEACHER </span><span> M.SC ,B.Ed ,DCA</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/310.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. CHANDA KUMARI <span> TEACHER </span><span> MA, B.Ed</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/306.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. JAGRITI SINGH <span> TEACHER </span><span> M.Sc ,B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/309.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. MINAKSHI KUMARI <span> PET </span><span> M.P.Ed ,M.Phill</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/308.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. ANJALI MUKHERJEE <span> TEACHER </span><span> MA,B.Ed</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/311.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. BINCY MADHUR <span> TEACHER </span><span> M.Sc,B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/315.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. SUDESHNA BOSE <span> TEACHER</span> <span> M.Sc ,B.Ed</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/317.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. SHOBHA <span> TEACHER <span> M.Sc, B.Ed</span> </span>  </p></div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/320.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. SHUBHRA BHUSHAN <span> TEACHER </span><span> B.Com ,B.Ed</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/321.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. JULIE BIRCH <span> TEACHER </span><span> M.COM ,BA ,B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/326.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. NISHA KUMARI <span> TEACHER </span><span> M.Sc, B. Ed</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/289.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. LILIAN SMITH <span> TEACHER </span><span> B.A PERSUING ,D.EL.Ed</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/332.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. SHAZIA SADAF <span> TEACHER </span><span> BA, B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/328.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. SWETA UPADHAYA <span> TEACHER </span><span> MA, B. Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/330.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. MITALI VORA <span> ART TEACHER </span><span> BA. Fine Arts</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/336.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. KANCHAN SINGH <span> TEACHER </span><span> MA, M. Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/333.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. JYOTI KUMARI <span> TEACHER </span><span> MA, B. Ed</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/343.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mr. GIBIN THOMAS <span> TEACHER </span><span> M.Tech</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/344.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. SANGITA SINGH <span> TEACHER </span><span> MA, B. Ed</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/351.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. ASHIMA RANI BERA <span> TEACHER </span><span> MA, B. Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1366.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mr. LAW KUMAR <span> TEACHER </span><span> MA, B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1380.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. RASHMI SINGH <span> TEACHER </span><span> BA, B. Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/341.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. REENA SHARMA <span> TEACHER </span><span> MA, B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/348.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. RINA SINGH <span> TEACHER </span><span> MA, B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1382.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mr. RAJEEV KUMAR SINGH <span> PET </span><span> MA, B.P.Ed</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/349.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. RUHI RANJAN <span> TEACHER </span><span> B.COM, MBA, B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/335.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. DIVYA SHARMA <span> TEACHER </span><span> BA, B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1379.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. EKTA BIHANY <span> TEACHER </span><span> B. Com, B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/355.jpg"/></LazyLoad> </div> <div className="desc"> <p> Mrs. RUBY RAY <span> TEACHER </span><span> B. SC, B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/342.jpg"/></LazyLoad> </div> <div className="desc"> <p> RACHANA SINGH <span> MUSIC TEACHER </span><span> BA, B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/352.jpg"/></LazyLoad> </div> <div className="desc"> <p> BINITA KUMARI SINGH <span> TEACHER </span><span> B. SC, B.Ed, MBA</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1374.jpg"/></LazyLoad> </div> <div className="desc"> <p> SMRITI SINGH <span> TEACHER </span><span> MCA, B.Ed Pursuing</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/345.jpg"/></LazyLoad> </div> <div className="desc"> <p> KUMARI SUNITA <span> TEACHER </span><span> MA, B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/340.jpg"/></LazyLoad> </div> <div className="desc"> <p> APARNA GUPTA <span> TEACHER </span><span> MA , BA , D.El.Ed , CTET</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/347.jpg"/></LazyLoad> </div> <div className="desc"> <p> MANJU KUMARI <span> TEACHER </span><span> MA, B.Ed</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/346.jpg"/></LazyLoad> </div> <div className="desc"> <p> ROBIN KUMAR MALLICK <span> TEACHER </span><span> BA, PGDBA(F), B.Ed Purusing</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1406.jpg"/></LazyLoad> </div> <div className="desc"> <p> KHUSHBU KUMARI <span> TEACHER </span><span> B.COM ,B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1378.jpg"/></LazyLoad> </div> <div className="desc"> <p> RAFAT ZAHEEN <span> TEACHER </span><span> B. A., M.C.A,  D. EL.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1368.jpg"/></LazyLoad> </div> <div className="desc"> <p> SUBRATO <span> TEACHER </span><span> B. Tech, B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1369.jpg"/></LazyLoad> </div> <div className="desc"> <p> SUMAN <span> TEACHER </span><span> MA, B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1371.jpg"/></LazyLoad> </div> <div className="desc"> <p> SUSHMITA RAJ <span> TEACHER </span><span> B.Tech, B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1409.jpg"/></LazyLoad> </div> <div className="desc"> <p> PANCHANAND PRAJAPATI <span> TEACHER </span><span> BA,DEl.Ed</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1383.jpg"/></LazyLoad> </div> <div className="desc"> <p> SHYAMAL KUMAR DAS <span> MUSIC TEACHER </span><span> BA, Diploma in Vocal and Tabla</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1384.jpg"/></LazyLoad> </div> <div className="desc"> <p> NEHA SEHGAL <span> TEACHER </span><span> MBA, B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1386.jpg"/></LazyLoad> </div> <div className="desc"> <p> CHANCHAL KUMAR <span> TEACHER </span><span> M. Com, B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1388.jpg"/></LazyLoad> </div> <div className="desc"> <p> MANISHA SINGH <span> TEACHER </span><span> MA, B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1407.jpg"/></LazyLoad> </div> <div className="desc"> <p> ANUPRIYA <span> TEACHER </span> <span>B.COM , B.Ed </span> </p></div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1390.jpg"/></LazyLoad> </div> <div className="desc"> <p> RUKHSAR ZEBA <span> TEACHER </span><span> BA, B.Ed</span> </p>  </div>. 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1391.jpg"/></LazyLoad> </div> <div className="desc"> <p> ANNU JHA <span> TEACHER </span><span> M. Com, B.Ed</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1392.jpg"/></LazyLoad> </div> <div className="desc"> <p> SWETA SINHA <span> TEACHER </span><span> M.Sc(IT), B.Ed ,</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1376.jpg"/></LazyLoad> </div> <div className="desc"> <p> SADAF NOORI <span> TEACHER </span><span> MA, B.Ed</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1393.jpg"/></LazyLoad> </div> <div className="desc"> <p> MEENAKSHI PD SINGH <span> TEACHER </span><span> BA, D. El. Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1394.jpg"/></LazyLoad> </div> <div className="desc"> <p> SHOJNA  P. P. <span> TEACHER </span><span> B.Sc, B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1426.jpg"/></LazyLoad> </div> <div className="desc"> <p> SOURAV KUMAR <span> PET TEACHER </span><span> B.Com, B.P. Ed, NIS.</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1408.jpg"/></LazyLoad> </div> <div className="desc"> <p> SWATI KHYALIA <span> TEACHER  </span> <span>  MA , M.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1416.jpg"/></LazyLoad> </div> <div className="desc"> <p> SURABHI BORAL <span> TEACHER </span> <span> MA</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1412.jpg"/></LazyLoad> </div> <div className="desc"> <p> SHABNAM PARVEEN <span> TEACHER </span> <span> B.SC, B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1418.jpg"/></LazyLoad> </div> <div className="desc"> <p> VANDANA SHARMA <span> PET TEACHER </span> <span> B.A. ,B.P.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1420.jpg"/></LazyLoad> </div> <div className="desc"> <p> NAGENDRA SINGH <span> ART TEACHER </span> <span> B.F.A ,M.F.A</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/default.png"/></LazyLoad> </div> <div className="desc"> <p> DEEPAK KUMAR JHA <span> TEACHER </span> <span> M.Sc , B.Ed</span> </p>  </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1422.jpg"/></LazyLoad> </div> <div className="desc"> <p> ANANDITA PATHAK <span> MUSIC TEACHER </span> <span> B.A ,M.MUSIC(VOCAL)</span> </p> </div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1424.jpg"/></LazyLoad> </div> <div className="desc"> <p> PRATIMA RANI ROY <span> TEACHER </span> <span> M.A ,MBA , D.El.Ed</span> </p></div> 
             </div>
             <div className="staffbox">
                <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1427.jpg"/></LazyLoad> </div> <div className="desc"> <p> ASHWINI KUMAR <span> MUSIC TEACHER </span>  </p></div>
            </div>
            </div>
             <h3 className="fac"><span> Non Teaching  Staff </span></h3>
             <div className="staffboxsec">
             <div className="staffbox">
              <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/314.jpg"/></LazyLoad> </div> <div className="desc"> <p> MADHUR P OOMMEN <span> ACCOUNTANT </span> <span> .</span> </p>  </div> 
             </div>
             <div className="staffbox">
              <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1419.jpg"/></LazyLoad> </div> <div className="desc"> <p> REEMA KUMARI <span> ASST. LIBRARIAN </span> <span> B.LIB</span> </p>  </div> 
             </div>
             <div className="staffbox">
              <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/253.jpg"/></LazyLoad> </div> <div className="desc"> <p> MONCY VARGHESE <span> LAB. Asst. </span> <span> .</span> </p>  </div> 
             </div> 
             <div className="staffbox">
              <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1413.jpg"/></LazyLoad> </div> <div className="desc"> <p> ANSU K ANIYAN <span> LAB ASSISTANT </span>   </p> 
             </div>
             </div>
             <div className="staffbox">
              <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1411.jpg"/></LazyLoad> </div> <div className="desc"> <p> SUMANT KUMAR THAKUR <span> LAB ASSISTANT </span>   </p> </div> 
             </div> 
             <div className="staffbox">
              <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1401.jpg"/></LazyLoad> </div> <div className="desc"> <p> RACHNA KUMARI <span> Receptionist </span>  </p> </div>  
             </div>
             <div className="staffbox">
              <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1425.jpg"/></LazyLoad> </div> <div className="desc"> <p> PRIYANKA <span> Receptionist </span> .</p> </div> 
             </div> 
             <div className="staffbox">
              <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1398.jpg"/></LazyLoad> </div> <div className="desc"> <p> PRAFULLA KUMAR SHRIVASTAVA <span> IT Asst. </span> <span> .</span> </p> </div> 
             </div>
             <div className="staffbox">
              <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1400.jpg"/></LazyLoad> </div> <div className="desc"> <p> PRASANJEET KUMAR <span> Lab Asst. </span> <span> .</span> </p> </div> 
             </div> 
             <div className="staffbox">
              <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/252.jpg"/></LazyLoad> </div> <div className="desc"> <p> REJI K. GEORGE <span> Supdt. </span> <span> .</span> </p> </div> 
             </div>
             <div className="staffbox">
              <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/274.jpg"/></LazyLoad> </div> <div className="desc"> <p> ARVIND KUMAR <span> LIBRARIAN </span> <span> M.Lib, B.Lib , B.Sc ,B.Ed</span> </p>  </div> 
             </div>
            </div>
              <h3 className="fac"><span>Support Staff </span></h3>
             <div className="staffboxsec">
             <div className="staffbox">
              <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/307.jpg"/></LazyLoad> </div> <div className="desc"> <p> BINCY SHALU <span> Office Staff </span> <span> .</span> </p></div> 
             </div>
             <div className="staffbox">
              <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/316.jpg"/></LazyLoad> </div> <div className="desc"> <p> ANITHA SIMON <span> Office Staff </span> <span> .</span> </p> </div> 
             </div> 
             <div className="staffbox">
              <div className="imgbox">
             <LazyLoad><img className="img-fluid mx-auto d-block" src="https://webapi.entab.info/api/image/MGMB/public/Images/Staffphoto/1397.jpg"/></LazyLoad> </div> <div className="desc"> <p> THOMAS N. MATHEW <span> Office Boy </span> <span> .</span> </p> </div> 
             </div>
            </div>
            </div>
            </div>
            </div>
            </div>
        </div>
     </>
  )
}

export default Faculty

